<script>
import {InsuredsListItem} from "@/views/osago/components";
import {email, maxLength, minLength, numeric, required, requiredIf} from "vuelidate/lib/validators";
import {getData} from "@/api/client";

export default {
    name: "InsurerInfoForm" ,
    components: {InsuredsListItem},
    model: {
        prop: 'info',
        event: 'change',
    },
    props: ['info'],
    computed: {
        searchInfo() {
            return this.$store.getters.GREENCARD_SEARCH_PARAMS
        },
        offerInfo() {
            return this.$store.getters.GREENCARD_INFO_OFFER
        }
    },
    watch: {
        'info.surname': async function(newValue, oldValue) {
            if(this.isfillData == true) return;
            if (newValue && newValue.length >= 3) {
                this.isLoadInsuredsListBySurname = true;
                await this.fetchClients(newValue,'surname');
            } else {
                this.insuredsListBySurname = [];
                this.isLoadInsuredsListBySurname = false;
            }
        },

        'info.name': async function(newValue, oldValue) {
            if(this.isfillData == true) return;
            if (this.searchInfo.userType.id === 2 && newValue && newValue.length >= 3) {
                this.isLoadInsuredsByCompanyName = true;
                await this.fetchClients(newValue,'name');
            } else {
                this.insuredsListBySurname = [];
                this.isLoadInsuredsByCompanyName = false;
            }
        },
        'info.erdpyCode': async function( newValue, oldValue) {
            if(this.isfillData == true) return;
            if (newValue && newValue.length >= 2) {
                this.isLoadInsuredsByErdpyCode = true;
                await this.fetchClients(newValue,'erdpyCode');
            }
        },
        'info.identificationCode': async function(newValue, oldValue) {
            if(this.isfillData == true) return;
            this.info.dateBirth = moment('01.01.1900', 'DD.MM.YYYY')
                .add(newValue.substr(0, 5) - 1, 'day')
                .format('DD.MM.YYYY');
            this.isLoadInsuredsList = true;
            await this.fetchClients(newValue,'identificationCode');
            delete this.errors['personal.identificationCode'];
        },
    },
    data() {
        return {
            isLoadInsuredsByErdpyCode: false,
            insuredsListByErdpyCode: [],
            isLoadInsuredsByCompanyName: false,
            insuredsListByCompanyName: [],
            utskModuleId: 17,
            // name_ua: null,
            // surname_ua: null,
            LetterMaskCyrError: false,
            LetterMaskError: false,
            errors: [],
            insuredsList: [],
            isLoadInsuredsList: false,
            isLoadInsuredsListBySurname: false,
            insuredsListBySurname: [],
        }
    },
    validations: {
        info: {
            contact: {
                fullName: {
                    required: requiredIf(function () {
                        return this.searchInfo.userType.id == 2
                    })
                },
                position: {
                    required: requiredIf(function () {
                        return this.searchInfo.userType.id == 2
                    })
                }
            },
            name_ua:{
                required,/*: requiredIf(function (nestedModel){
                    return parseInt(this.offerInfo.moduleId) === parseInt(this.utskModuleId);
                }),*/
                minLength: minLength(2),
                maxLength: maxLength(100),
                cyrillic: function(value) {
                    // if(this.offerInfo && parseInt(this.offerInfo.moduleId) === parseInt(this.utskModuleId)){
                        if(this.searchInfo.userType.id == 2) {
                            return this.onlyCyrAndNumAndSpecials(value);
                        }else if(this.searchInfo.userType.id == 1){
                            return this.onlyCyrCharactersAndNum(value);
                        }else{
                            return true;
                        }
                    // }else{
                    //     return true;
                    // }
                }
            },

            surname_ua:{
                required: requiredIf(function (nestedModel) {
                    // parseInt(this.offerInfo.moduleId) == parseInt(this.utskModuleId) &&
                    return this.searchInfo.userType.id === 1
                }),
                minLength: minLength(2),
                maxLength: maxLength(100),
                cyrillic: function (value) {
                //     if (this.offerInfo && parseInt(this.offerInfo.moduleId) === parseInt(this.utskModuleId)) {
                        return this.onlyCyrCharacters(value);
                //     } else {
                //         return true;
                //     }
                }
            },
            identificationCode:{required: requiredIf(function (nestedModel) {
                    return this.searchInfo.userType.id != 2 && ! this.info.withoutIdentificationCode
                }), customMinLength(value) {
                    if (this.searchInfo.userType.id === 2 || this.info.withoutIdentificationCode) {
                        return true;
                    }
                    return value !== null && value.length === 10;
                }, numeric},
            erdpyCode:{required: requiredIf(function (nestedModel) {
                    return this.searchInfo.userType.id === 2
                }), minLength: minLength(8), numeric},
            name:{required, minLength: minLength(2), maxLength: maxLength(100),
                onlyLatinCharacters(value){
                    if(this.searchInfo.userType.id == 2) {
                        return this.onlyLatinAndNumAndSpecials(value);
                    }else if(this.searchInfo.userType.id == 1){
                        return this.onlyLatinCharactersAndNum(value);
                    }else{
                        return true;
                    }
                }
            },
            surname:{required: requiredIf(function (nestedModel) {
                    return this.searchInfo.userType.id === 1
                }), minLength: minLength(2), maxLength: maxLength(100),
                onlyLatinCharacters(value){
                    return this.onlyLatinCharacters(value);
                }},

            dateBirth: {required: requiredIf(function (nestedModel) {
                    return !(this.searchInfo.userType.id === 2)
                }), minLength: minLength(10), maxLength: maxLength(10),
                customMinLength(value) {
                    if (!!this.searchInfo && this.searchInfo.userType.id === 2 ) {
                        return true;
                    }
                    return value !== null && value.length === 10;
                },
                daysInMonth: (value) => { return true;
                    if (value === null) {
                        return  true
                    }
                    if (!!this.searchInfo && this.searchInfo.userType.id === 2 ) {
                        return true;
                    }
                    return (parseInt(moment(value, 'DD.MM.YYYY').format('DD'))) <= moment(value, 'DD.MM.YYYY').daysInMonth();
                },
                minYears: (value) => { return true;
                    if (value === null ) {
                        return  true
                    }
                    if (!!this.searchInfo && this.searchInfo.userType.id === 2 ) {
                        return true;
                    }
                    return (parseInt(moment(value, 'DD.MM.YYYY').diff(moment(), 'years')) * -1) > 17;
                },
                minMonth: (value) => {
                    return  true
                },
                errorBirthDate: function() {
                    return !this.errorBirthDate();
                }
            },
            phone:{required,minLength: minLength(19)},
            mail:{required,email},
            city:{required, onlyLatinCharacters(value){
                    return this.onlyLatinCharacters(value);
                }},
            street:{customMinLength(value) {
                    if (this.info.street == null || this.info.street == "") {
                        return true;
                    }
                    return value !== null && value.length >= 5;
                }, onlyLatinAndNumAndSpecials(value){
                    console.log(this.onlyLatinAndNumAndSpecials(value))
                    return this.onlyLatinAndNumAndSpecials(value);
                }},
            house:{minLength: minLength(1), houseLatinAndNumAndSpecials(value){
                    return this.houseLatinAndNumAndSpecials(value);
                }},
            apartment:{minLength: minLength(1), onlyLatinCharactersAndNum(value){
                    return this.onlyLatinCharactersAndNum(value);
                }},
        },
    },
    methods: {
        onlyCyrCharactersAndNum(str) {
            if(str == null || str == "") return true;
            return /^[А-Яа-яЇїІіҐґ+'ʼ"Єє`0-9 ]+$/.test(str);
        },
        async fetchClients(query, type) {
            if (!query) {
                this.isLoadInsuredsList = false;
                return;
            }
            this.insuredsList = [];
            let params = {
                "requestType": "clientsByUserId",
                "seatchText": query,
                "customerType":this.searchInfo.userType.id
            };
            clearTimeout(this.fetchClientsTimeout);
            this.fetchClientsTimeout = setTimeout(async () => {
                let response = await getData(params);
                if(type == "identificationCode"){
                    this.insuredsList = response.data.data.items;
                }else if (type == "erdpyCode"){
                    this.insuredsListByErdpyCode = response.data.data.items;
                }else if (type == "surname"){
                    this.insuredsListBySurname = response.data.data.items;
                }else if (type == "name"){
                    this.insuredsListByCompanyName = response.data.data.items;
                }
                this.isLoadInsuredsList = false;
                this.isLoadInsuredsByErdpyCode = false;
                this.isLoadInsuredsByCompanyName = false;
                this.isLoadInsuredsListBySurname = false;
            }, 1000);
        },
        onlyLatinAndNumAndSpecials(str) {
            if(str == null || str == "") return true;

            return /^[a-zA-Z0-9+"'ʼ\-.\/ ]+$/.test(str);
        },
        onlyCyrCharacters(str) {
            if(str == null || str == "") return true;
            return /^[А-Яа-яЇїІіҐґ'ʼЄєщЩ` ]+$/.test(str);
        },
        onlyLatinCharacters(str) {
            if(str == null || str == "") return true;
            return /^[a-zA-Z ]+$/.test(str);
        },
        onlyCyrAndNumAndSpecials(str) {
            if(str == null || str == "") return true;

            return /^[А-Яа-яЇїІіҐґ+'ʼ"Єє`0-9\-.\/ ]+$/.test(str);
        },
        hasError(name) {
            return this.errors[name] !== undefined;
        },
        validateTaxNumber(tax_number, date) {
            let tax_numberDate = moment('01.01.1900', 'DD.MM.YYYY').add(tax_number.substr(0, 5) - 1, 'day');
            let birthDate = moment(date, 'DD.MM.YYYY');

            return tax_numberDate.isSame(birthDate, 'year')
                && tax_numberDate.isSame(birthDate, 'month')
                && tax_numberDate.isSame(birthDate, 'day');
        },
        errorTaxNumber: function () {

            if (this.searchInfo.userType.id == 2) {
                return  false;
            }

            if(this.info.identificationCode == "" || this.info.identificationCode == null) {
                return false;
            }

            if(this.info.dateBirth == "" || this.info.dateBirth == null) {
                return false;
            }

            if(this.info.dateBirth.length == 10 && this.info.identificationCode.length == 10 && ! this.validateTaxNumber(this.info.identificationCode, this.info.dateBirth)) {
                this.errors['personal.identificationCode'] = ["ІПН та Дата народження не співпадають"];

                return true;
            }

            return false;
        },
        errorBirthDate: function () {
            if(this.info.dateBirth == "" || this.info.dateBirth == null || this.info.dateBirth == "") {
                return false;
            }

            if((parseInt(moment(this.info.dateBirth, 'DD.MM.YYYY').diff(moment(), 'years')) * -1) < 18) {
                this.errors['personal.dateBirth'] = ["Страхувальнику повинно бути більше 18 років"];
                return true;
            }else if((parseInt(moment(this.info.dateBirth, 'DD.MM.YYYY').diff(moment(), 'years')-1) * -1) > 100) {
                this.errors['personal.dateBirth'] = ["Страхувальнику повинно бути не більше 100 років"];
                return true;
            } else {
                console.log(moment(this.info.dateBirth, 'DD.MM.YYYY').isValid(), 'test')
                return !moment(this.info.dateBirth, 'DD.MM.YYYY').isValid()
            }

            return false;
        },
        getError(name) {
            if(this.hasError(name)) {
                return this.errors[name][0].replace(name, '');
            }

            return "";
        },
        LetterMask(e){
            let char = String.fromCharCode(e.keyCode); // Get the character
            if(/^[A-Za-z ]+$/.test(char)) {
                this.LetterMaskError = false;
                return true;
            } // Match with regex
            else {
                this.LetterMaskError = true;
                e.preventDefault();
            }
        },
        LetterCyrMask(e){
            let char = String.fromCharCode(e.keyCode); // Get the character
            if(/^[А-Яа-яЇїІіҐґ+'ʼЄє` ]+$/.test(char)) {
                this.LetterMaskCyrError = false;
                return true;
            } // Match with regex
            else {
                this.LetterMaskCyrError = true;
                e.preventDefault();
            }
        },
        onlyLatinCharactersAndNum(str) {
            if(str == null || str == "") return true;
            return /^[a-zA-Z0-9 ]+$/.test(str);
        },
        houseLatinAndNumAndSpecials(str) {
            if(str == null || str == "") return true;

            if (/^[a-zA-Z0-9\-.\/ ]+$/.test(str)) {
                // additional check for any number
                return /\d/.test(str);
            }
            return false;
        },
        LetterNumMask(e){
            let char = String.fromCharCode(e.keyCode); // Get the character
            if(/^[A-Za-z0-9 ]+$/.test(char)) {
                this.LetterMaskError = false;
                return true;
            } // Match with regex
            else {
                this.LetterMaskError = true;
                e.preventDefault();
            }
        },
        LetterNumSymbolsMask(e){
            let char = String.fromCharCode(e.keyCode); // Get the character
            if(/^[A-Za-z0-9\-.\/ ]+$/.test(char)) {
                this.LetterMaskError = false;
                return true;
            } // Match with regex
            else {
                this.LetterMaskError = true;
                e.preventDefault();
            }
        },
        selectInsuredInfo(insured) {
            this.$emit('selectInsuredInfo', insured);
        },
    },
}
</script>

<template>
<div class="row">
    <div class="col-12">
        <h3>
            <span>Дані страхувальника (Покупець)</span>
        </h3>
    </div>
    <div class="col-lg-12 row passport-row " v-if="searchInfo.userType.id == 2">
        <div class="col-lg-4">
            <div class="form-group erdpy-code-block">
                <input v-loading="isLoadInsuredsByErdpyCode"  v-model="info.erdpyCode" v-mask="'########'" type="text" placeholder="ЄДРПОУ" :class="['form-control erdpy-code-field', {invalid: ($v.info.erdpyCode.$error)}]">
                <div v-if="isLoadInsuredsByErdpyCode" class="greencard-spiner load-insured-list spinner-border spinner-border-sm" role="status">
                </div>
                <small class="error" v-if="!$v.info.erdpyCode.required && $v.info.erdpyCode.$error">Вкажіть ЄДРПОУ</small>
                <small class="error" v-if="!$v.info.erdpyCode.numeric">ЄДРПОУ повинен містити тільки цифри</small>

                <small class="error" v-if="!$v.info.erdpyCode.minLength && $v.info.erdpyCode.$error">ЄДРПОУ повинен містити мінімум 8 символів</small>
                <div v-if="insuredsListByErdpyCode && insuredsListByErdpyCode.length > 0" class="autocomplete-erdpy-code">
                    <div @click="selectInsuredInfo(insured)" class="inn-items mb-1 mt-2" v-for="insured in insuredsListByErdpyCode">
                        <insureds-list-item :is-legal-type="true" :insured="insured"></insureds-list-item>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-4">
            <div class="form-group company-name-block">
                <input v-model="info.name"
                       maxlength="100"
                       type="text"
                       placeholder="Назва (Тільки латинськи літери, цифри, та символи - .)"
                       :class="['form-control  company-name-field',
                                                               {invalid: ($v.info.name.$error && !onlyLatinAndNumAndSpecials(info.name))}]">
                <div v-if="isLoadInsuredsByCompanyName" class="greencard-spiner load-insured-list spinner-border spinner-border-sm" role="status"></div>
                <div v-if="insuredsListByCompanyName && insuredsListByCompanyName.length > 0" class="autocomplete-company-name">
                    <div @click="selectInsuredInfo(insured)" class="inn-items mb-1 mt-2" v-for="insured in insuredsListByCompanyName">
                        <insureds-list-item :is-legal-type="true" :insured="insured"></insureds-list-item>
                    </div>
                </div>
                <small class="error" v-if="$v.info.name.$error">Вкажіть назву підприємства</small>
                <small class="error" v-if="($v.info.name.$error && !onlyLatinAndNumAndSpecials(info.name))">Тільки латинськи літери, цифри, та символи - .</small>
            </div>
        </div>
<!--        v-if="offerInfo.moduleId == utskModuleId"-->
        <div class="col-lg-4">
            <div class="form-group">
                <input v-model="info.name_ua"
                       @change="/*getClientDataForUpdating()*/"
                       type="text"
                       placeholder="Назва (Тільки кирилиця)"
                       v-on:keypress="LetterCyrMask($event)"
                       :class="['form-control', {invalid: $v.info.name_ua.$error && !onlyCyrAndNumAndSpecials(info.name_ua)}]">
                <small class="error" v-if="$v.info.name_ua.$error">Вкажіть назву підприємства </small>
                <small class="error" v-if="LetterMaskCyrError || !onlyCyrAndNumAndSpecials(info.name_ua)">Тільки кирилиця</small>
            </div>
        </div>
    </div>
    <div v-else-if="searchInfo.userType.id == 1" class="col-lg-12 passport-row gr-steps-passport-block">
        <div class="col-lg-4">
            <div class="form-group tooltip-group inn-block">
                <input  type="text" placeholder="ІПН"
                        :disabled="info.withoutIdentificationCode"
                        v-model="info.identificationCode"
                        :class="['form-control inn-field', {invalid: hasError('personal.identificationCode') || ($v.info.identificationCode.$error)}]"
                        v-mask="'##########'" maxlength="10">
                <div v-if="insuredsList && insuredsList.length > 0" class="autocomplete-inn">
                    <div @click="selectInsuredInfo(insured)" class="inn-items mb-1 mt-2" v-for="insured in insuredsList">
                        <insureds-list-item :insured="insured"></insureds-list-item>
                    </div>
                </div>
                <i v-if="! isLoadInsuredsList" data-html="true" data-toggle="tooltip" data-select="true"
                   title="" class="icon question"
                   data-original-title="<img src='https://ukasko.ua/images/tooltips/inn.png'>"></i>
                <div v-else class="greencard-spiner load-insured-list spinner-border spinner-border-sm" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                <small class="error"
                       v-if="!$v.info.identificationCode.required && $v.info.identificationCode.$error">Введіть ІПН</small>
                <small class="error"
                       v-else-if="(!$v.info.identificationCode.customMinLength && $v.info.identificationCode.$error)">
                    Введіть коректно ІПН
                </small>
                <small class="error" v-if="errorTaxNumber()">
                    ІПН та Дата народження не співпадають
                </small>
            </div>
        </div>

        <div class="col-lg-4">
            <div class="form-group">
                <input v-model="info.name"
                       @change="/*getClientDataForUpdating()*/"
                       type="text"
                       placeholder="Ім’я (Тільки латинськи літери)"
                       v-on:keypress="LetterMask($event)"
                       :class="['form-control', {invalid: ($v.info.name.$error || !onlyLatinCharacters(info.name))}]">
                <small class="error" v-if="!$v.info.name.required && $v.info.name.$error">Вкажіть ім’я </small>
                <small class="error" v-if="LetterMaskError || !onlyLatinCharacters(info.name)">(Тільки латинські літери)</small>
            </div>
        </div>
        <div class="col-lg-4">
            <div class="form-group surname-block">
                <input v-model="info.surname"
                       maxlength="100"
                       type="text"
                       v-on:keypress="LetterMask($event)"
                       placeholder="Прізвище (Тільки латинські літери)"
                       :class="['form-control surname-field',
                                                               {invalid: ($v.info.surname.$error && (!$v.info.surname.required || !$v.info.surname.minLength || !$v.info.surname.maxLength)) ||  !onlyLatinCharacters(info.surname)}]">
                <div v-if="isLoadInsuredsListBySurname" class="greencard-spiner load-insured-list spinner-border spinner-border-sm" role="status"></div>
                <div v-if="insuredsListBySurname && insuredsListBySurname.length > 0" class="autocomplete-surname">
                    <div @click="selectInsuredInfo(insured)" class="inn-items mb-1 mt-2" v-for="insured in insuredsListBySurname">
                        <insureds-list-item :insured="insured"></insureds-list-item>
                    </div>
                </div>
                <small class="error" v-if="LetterMaskError || ($v.info.surname.$error && onlyLatinCharacters(info.surname))">Вкажіть прізвище (Тільки латинськи літери)</small>
                <small class="error"
                       v-else-if="! $v.info.surname.minLength || ! $v.info.surname.maxLength">
                    Введіть коректно прізвище</small>
            </div>
        </div>

        <div class="col-lg-4">
            <div class="form-group">
                <input v-mask="'##.##.####'" placeholder="Дата народження"
                       v-model="info.dateBirth"
                       @change="/*getClientDataForUpdating()*/"
                       :class="['form-control', 'form-input_date', {invalid: searchInfo.userType.id == 1 && $v.info.dateBirth.$error &&
                                                                ((!$v.info.dateBirth.customMinLength) ||
                                                                 (!$v.info.dateBirth.minYears) ||
                                                                 (!$v.info.dateBirth.minMonth) ||
                                                                  (!$v.info.dateBirth.errorBirthDate || hasError('personal.errorBirthDate')))}]">
                <small class="error"
                       v-if="!$v.info.dateBirth.required && $v.info.dateBirth.$error">Введіть дату народження</small>
                <small class="error"
                       v-else-if="searchInfo.userType.id == 1 && $v.info.dateBirth.$error &&((!$v.info.dateBirth.customMinLength) || !$v.info.dateBirth.daysInMonth || !$v.info.dateBirth.errorBirthDate)">
                    Введіть коректну дату народження</small>
                <small class="error" v-else-if="searchInfo.userType.id == 1 && (!$v.info.dateBirth.minYears || !$v.info.dateBirth.minMonth)">
                    Страхувальнику повинно бути більше 18 років
                </small>
                <small class="invalid-feedback error" v-else-if="searchInfo.userType.id == 1 && hasError('personal.dateBirth')" v-html="getError('personal.dateBirth')"></small>
            </div>
        </div>
<!--        v-if="offerInfo.moduleId == utskModuleId"-->
        <div class="col-lg-8">
            <div class="row">
                <div class="col-lg-6">
                    <div class="form-group">
<!--                        @change="/*getClientDataForUpdating()*/"-->
                        <input v-model="info.name_ua"
                               type="text"
                               placeholder="Ім’я (Тільки кирилиця)"
                               v-on:keypress="LetterCyrMask($event)"
                               :class="['form-control', {invalid: ($v.info.name_ua.$error || (!onlyCyrCharacters(info.name_ua)))}]">
                        <small class="error" v-if="$v.info.name_ua.$error">Вкажіть ім’я</small>
                        <small class="error" v-if="LetterMaskCyrError || !onlyCyrCharacters(info.name_ua)">Тільки кирилиця</small>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="form-group surname-block">
<!--                        @change="/*getClientDataForUpdating()*/"-->
                        <input v-model="info.surname_ua"
                               maxlength="100"
                               type="text"
                               v-on:keypress="LetterCyrMask($event)"
                               placeholder="Прізвище (Тільки кирилиця)"
                               :class="['form-control surname-field',
                                                                 {invalid: ($v.info.surname_ua.$error || (!$v.info.surname_ua.minLength) || (!$v.info.surname_ua.maxLength)) || !onlyCyrCharacters(info.surname_ua)}]">
                        <div v-if="isLoadInsuredsListBySurname" class="greencard-spiner load-insured-list spinner-border spinner-border-sm" role="status"></div>
                        <div v-if="insuredsListBySurname && insuredsListBySurname.length > 0" class="autocomplete-surname">
                            <div @click="selectInsuredInfo(insured)" class="inn-items mb-1 mt-2" v-for="insured in insuredsListBySurname">
                                <insureds-list-item :insured="insured"></insureds-list-item>
                            </div>
                        </div>
                        <small class="error" v-if="$v.info.surname_ua.$error">Вкажіть прізвище (Тільки кирилиця)</small>
                        <small class="error" v-if="LetterMaskCyrError || ($v.info.surname_ua.$error && !onlyCyrCharacters(info.surname_ua))">Тільки кирилиця</small>
                        <small class="error"
                               v-else-if="! $v.info.surname_ua.minLength || ! $v.info.surname_ua.maxLength">
                            Введіть коректно прізвище</small>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-lg-4">
        <div class="form-group">
            <input v-model.trim="info.phone"
                   v-mask="'+38 (0##) ###-##-##'"
                   type="text"
                   placeholder="Телефон"
                   @change="/*getClientDataForUpdating()*/"
                   :class="['form-control', {invalid: $v.info.phone.$error}]">
            <small class="error" v-if="$v.info.phone.$error">Вкажіть телефон</small>
            <small class="error" v-else-if="!$v.info.phone.minLength">Введіть номер в форматі 380xxxxxxxx</small>
        </div>
    </div>
    <div class="col-lg-4">
        <div class="form-group">
            <input v-model.trim="info.mail"
                   type="text"
                   @change="/*getClientDataForUpdating()*/"
                   placeholder="Ел. пошта"
                   :class="['form-control', {invalid: $v.info.mail.$error}]">
            <small class="error" v-if="$v.info.mail.$error">Вкажіть пошту</small>
            <small class="error" v-else-if="!$v.info.mail.email">Введіть коректно пошту</small>
        </div>
    </div>
    <div class="col-lg-4">
        <div class="form-group">
            <input v-model.trim="info.city"
                   @change="/*getClientDataForUpdating()*/"
                   v-on:keypress="LetterMask($event)"
                   type="text" placeholder="Вкажіть місто (Тільки латинськи літери)"
                   :class="['form-control', {invalid: ($v.info.city.$error && onlyLatinCharacters(info.city))}]">
            <small class="error" v-if="$v.info.city.$error">Вкажіть місто</small>
            <small class="error" v-else-if="!$v.info.city">Введіть коректно місто</small>
            <small class="error" v-if="LetterMaskError || ($v.info.city.$error && !onlyLatinCharacters(info.city))">Тільки латинські літери</small>
        </div>
    </div>
    <div class="col-lg-4">
        <div class="form-group">
            <input v-model="info.street"
                   @change="/*getClientDataForUpdating()*/"
                   type="text"
                   v-on:keypress="LetterNumSymbolsMask($event)"
                   placeholder="Вул. (Тільки латинські літери, цифри та спецсимвол “-“)"
                   :class="['form-control', {invalid: ($v.info.street.$error && !onlyLatinAndNumAndSpecials(info.street))}]">
            <small class="error" v-if="$v.info.street.$error">Вкажіть вулицю мін. 5 літер</small>
            <small class="error" v-if="LetterMaskError || ($v.info.street.$error && !onlyLatinAndNumAndSpecials(info.street))">Тільки латинські літери, цифри та спецсимвол “-“</small>
        </div>
    </div>
    <div class="col-lg-4">
        <div class="form-group">
            <input v-model="info.house"
                   v-on:keypress="LetterNumSymbolsMask($event)"
                   type="text"
                   placeholder="№ буд."
                   :class="['form-control', {invalid: ($v.info.house.$error && !houseLatinAndNumAndSpecials(info.house))}]">
            <small class="error" v-if="$v.info.house.$error">Вкажіть № будинку</small>
            <small class="error" v-if="LetterMaskError || ($v.info.house.$error && !houseLatinAndNumAndSpecials(info.house))">Тільки латинські літери, цифри та символи "-/"</small>
        </div>
    </div>
    <div class="col-lg-4">
        <div class="form-group">
            <input v-model="info.apartment"
                   v-on:keypress="LetterNumMask($event)"
                   type="text"
                   placeholder="№ кв."
                   :class="['form-control', {invalid: ($v.info.apartment.$error && !onlyLatinCharactersAndNum(info.apartment))}]"
            >
            <small class="error" v-if="$v.info.apartment.$error">Вкажіть № квартири</small>
            <small class="error" v-if="LetterMaskError || ($v.info.apartment.$error && !onlyLatinCharactersAndNum(info.apartment))">Тільки латинські літери та цифри</small>
        </div>
    </div>
    <div v-if="searchInfo.userType.id === 2 && info.contact" class="col-lg-12">
        <div class="row">
            <div class="col-lg-4">
                <div class="form-group">
                    <input v-model="info.contact.fullName"
                           type="text"
                           placeholder="Контактна особа, ПІБ"
                           :class="['form-control', {invalid: ($v.info.contact.fullName.$error)}]"
                    >
                    <small class="error" v-if="$v.info.contact.fullName.$error">Вкажіть контактну особу</small>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="form-group">
                    <input v-model="info.contact.position"
                           type="text"
                           placeholder="Посада"
                           :class="['form-control', {invalid: ($v.info.contact.position.$error)}]"
                    >
                    <small class="error" v-if="$v.info.contact.position.$error">Вкажіть посаду</small>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="form-group">
                    <input v-model="info.contact.documentType"
                           type="text"
                           placeholder="Тип документа, на підставі якого особа має повноваження"
                           class="form-control"
                    >
                </div>
            </div>
            <div class="col-lg-4">
                <div class="form-group">
                    <input v-model="info.contact.documentNumber"
                           v-on:keypress="LetterNumMask($event)"
                           type="text"
                           placeholder="Номер документа, на підставі якого особа має повноваження"
                           class="form-control"
                    >
                </div>
            </div>
            <div class="col-lg-4">
                <div class="form-group">
                    <input v-model="info.contact.documentDate"
                           v-mask="'##.##.####'"
                           type="text"
                           placeholder="Дата документа, на підставі якого особа має повноваження"
                           class="form-control"
                    >
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-4" v-if="searchInfo.userType.id === 2 && info.contact">
        <div class="form-group">
            <label>Статус державного (комунального) підприємства?</label>
            <div class="radiolist">
                <div class="radioitem mt-3">
                    <input type="radio" name="taxiType" id="taxitypeEnabled" :checked="info.isDP == true">
                    <label for="taxitypeEnabled" @click="info.isDP = true">Так</label>
                    <div class="check"></div>
                </div>
                <div class="radioitem mt-3">
                    <input type="radio" name="taxiType" id="taxitypeDisabled" :checked="info.isDP == false">
                    <label for="taxitypeDisabled" @click="info.isDP = false">Ні</label>
                    <div class="check"></div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-4" v-else>
        <div class="form-group">
            <label>Статус ФОП?</label>
            <div class="radiolist">
                <div class="radioitem mt-3">
                    <input type="radio" name="taxiType" id="taxitypeEnabled" :checked="info.isFop == true">
                    <label for="taxitypeEnabled" @click="info.isFop = true">Так</label>
                    <div class="check"></div>
                </div>
                <div class="radioitem mt-3">
                    <input type="radio" name="taxiType" id="taxitypeDisabled" :checked="info.isFop == false">
                    <label for="taxitypeDisabled" @click="info.isFop = false">Ні</label>
                    <div class="check"></div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<style scoped>

</style>
