<script>
import {maxLength, minLength, numeric, required, requiredIf} from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import {saveClient} from "@/api/client";

//TODO need refactoring
export default {
    model: {
      prop: 'car',
      event: 'change',
    },
    props: {
        car: {
            type: Object,
            required: true,
            default: function () {
                return {
                    number: null,
                    brand: null,
                    model: null,
                    autoCategory: null,
                    vin: null,
                    withoutVin: false,
                    year: null,
                    ownWeight: null,
                    totalWeight: null,
                    nSeating: null,
                    engineSize: null,
                }
            }
        }
    },
    name: "CarInfoForm",
    components: {Multiselect},
    data() {
        return {
            carType: null,
            engineVolume: null,
        }
    },
    watch: {
        'car.number':function(val) {
            if (this.car.autoCategory) {
                this.getCarTypeByAutoCategory(this.car.autoCategory)
            }
        },
    },
    computed: {
        carTypes() {
            return this.$store.state.autoCategoryTypes.types.map(item => item.category)
        },
        carTypeOptions() {
            return this.carType ? this.$store.state.autoCategoryTypes.types.find(item => item.category === this.carType).list : [];
        },
        carBrands: {
            get() {
                return this.$store.state.carsStore.marks.map(item => item.name)
            },
            set(value) {
                this.$store.state.carsStore.marks.push(value);
            }
        },
        carModels: {
            get() {
                if (this.car.brand) {
                    let mark = this.$store.state.carsStore.marks.find(item => item.name.toLowerCase() === this.car.brand.toLowerCase());

                    let car_mark_id = mark ? mark.id : null;

                    return this.$store.state.carsStore.models.filter(model => model.car_mark_id === car_mark_id)
                        .map(item => item.name)
                }

                return [];
            },
        },
    },
    validations: {
        car: {
            vin: {
                required: requiredIf(function (nestedModel) {
                    return !this.car.withoutVin
                }),
                minLength: minLength(5),
                maxLength: maxLength(17)
            },
            year: {
                maxLength: maxLength(4),
                customMinLength(value) {
                    // if (value == "" || value == null) {
                    //     return true;
                    // }
                    return value !== null && value.toString().length === 4;
                },
                numeric
            },
            brand: {
                required,
                minLength: minLength(1)
            },
            model: {
                required,
                minLength: minLength(1)
            },
            city: {
                required,
                minLength: minLength(1)
            },
            number: {
                required,
                numberValidate(value) {
                    if (value && value.length >= 3 && value.length <= 20) {
                        return true
                    } else if (value && value.length >= 5 && value.length <= 8) {
                        return true
                    }
                    return false
                }
            },
            ownWeight: {
                required,
                numeric
            },
            totalWeight: {
                required,
                numeric
            },
            nSeating: {
                required: requiredIf(function(nestedModel){
                    return this.car.autoCategory !== 'E' && this.car.autoCategory !== 'F'
                }),
                numeric
            },
            engineSize: {
                required: requiredIf(function(nestedModel){
                    return this.car.autoCategory !== 'B5' && this.car.autoCategory !== 'E' && this.car.autoCategory !== 'F'
                }),
                numeric
            },
            kvt: {
                required: requiredIf(function(nestedModel){
                    return this.car.autoCategory === 'B5'
                }),
                numeric
            },
        },
        carType: {
            required
        },
        engineVolume: {
            required
        }
    },
    methods: {
        addCar(newEl, type) {
            if (typeof newEl === 'string') {
                const newCar = {id: Date.now(), name: newEl};
                if (type === 'model') {
                    this.carModels.push(newCar);
                    this.car.model = newEl;
                } else if (type === 'brand') {
                    this.carBrands = newCar;
                    this.car.brand = newEl;
                }
            } else {
                if (type === 'model') {
                    this.car.model = newEl;
                } else if (type === 'brand') {
                    this.car.brand = newEl;
                }
            }
        },
        getCarTypeByAutoCategory(value) {
            let engine;
            this.carType = this.$store.state.autoCategoryTypes.types.find(item => {
                engine = item.list.find(i => i.id === value);

                return engine;
            }).category
            this.engineVolume = engine;
        },
        checkVin: function () {
            if (this.car.vin === null || this.car.vin.length == '') {
                return false;
            }

            if (this.car.vin.match(/\d{1,}/) === null) {
                return true
            }

            if (this.car.vin.match(/[i,o,q]/i) !== null) {
                return true
            }

            //[A,В,Е,Ё,Й,К,М,Н,О,Р,С,Т,У,Х,Ъ,Ь] - не можно
            //[Б,Г,Д,Э,Ж,З,И,Л,П,Ф,Ц,Ч,Ш,Щ,Ю,Я] - можно
            if (this.car.vin.match(/[А,В,Е,Ё,Й,К,М,Н,О,У,Х,Ъ,Ь]/i) !== null) {
                return true

            }

            return false;
        },
        isInvalid(val) {
            return val == null// && this.submitCheck;
        },
        checkInvalidCartype() {
            const orderData = this.getStoredOrderData();
            return !(orderData?.requestData?.customerType === 3 && this.carTypeOption.value === 'B4');
        },
        getStoredOrderData() {
            return this.$store.state.osagoStore.infoOrder;
        },
        disableVin() {
            this.car.vin = "";
        },
        getNumberMask() {
            return 'MMMMMMMMM';
        },
        identifyModel() {
            if (this.car.number && this.car.number.length) {
                axios({url: '/api/directories/car/' + this.car.number.trim(), method: 'GET'}).then(({data}) => {
                    let result = data.data;
                    if (result) {
                        this.car.brand = result.mark;
                        // this.carBrands.push(this.car.brand)
                        this.car.model = result.model;
                        // this.carModels.push(this.car.model)
                        this.car.year = result.year;
                        this.car.vin = result.vin;
                        this.car.autoCategory = result.autoCategory;
                        this.getCarTypeByAutoCategory(this.car.autoCategory);

                        this.car.withoutVin = !this.car.vin;
                        if (result.additionalParameters) {
                            this.car.engineSize = result.additionalParameters.capacity;
                            this.car.kvt = result.additionalParameters.kvt;
                            this.car.nSeating = result.additionalParameters.numberOfSeats;
                            this.car.ownWeight = result.additionalParameters.ownWeight;
                            this.car.totalWeight = result.additionalParameters.totalWeight;
                        }
                    }
                }).catch(err => (console.log(err)));
            }
        },
    },
    mounted() {
        // if (this.$router)
    }
}
</script>

<template>
    <div class="row">
        <div class="col-12">
            <h3>
                <span>Введіть номерний знак</span>
            </h3>
        </div>
        <div class="col-lg-6">
            <div class="form-group">
                <input v-model="car.number"
                       type="text"
                       v-mask="getNumberMask()"
                       placeholder="Введіть наприклад: АХ1111ВС"
                       :id="!$v.car.number.numberValidate"
                       :class="['form-control', 'uppercase', {invalid: $v.car.number.$error}]">
                <div v-if="$v.car.number.$error">
                    <small class="error"
                           v-if="!$v.car.number.required">Введіть номер</small>
                    <small class="error"
                           v-else-if="car.number && !$v.car.number.numberValidate">Введіть коректний номер</small>
                </div>
            </div>
        </div>
        <div class="col-lg-6">
            <span class="tip-text">
               <a @click.stop.prevent="identifyModel()" href="#">Знайти за номером</a>
            </span>
        </div>

        <div class="col-lg-6">
            <div class="form-group">
<!--                Invalid(this.carType)-->
                <multiselect
                    :class="['form-control', {invalid : $v.carType.$error}]"
                    v-model="carType"
                    :options="carTypes"
                    placeholder="Тип ТЗ*"
                    :max-height="120"
                    :allow-empty="false"
                    @select="engineVolume = null"
                    ></multiselect>
<!--                label="name"-->
                <div v-if="$v.carType.$error">
                    <small class="error" v-if="!$v.engineVolume.required">Вкажіть тип ТЗ</small>
                </div>
            </div>
        </div>
<!--        v-if="carTypeOptions.length !== 0"-->
        <div class="col-lg-6" >
            <div class="form-group">
                <multiselect
                    :class="['form-control', {invalid: $v.engineVolume.$error}]"
                    v-model="engineVolume"
                    :options="carTypeOptions"
                    :max-height="120"
                    label="name"
                    :allow-empty="false"
                    placeholder="Виберіть об'єм двигуна"
                    @select="car.autoCategory = $event.id"
                    :searchable="false"></multiselect>
                <div v-if="$v.engineVolume.$error">
                    <small class="error" v-if="!$v.engineVolume.required">Вкажіть параметри ТЗ</small>
                    <small class="error" v-if="(!checkInvalidCartype())">Пільгове страхування авто з объемом більше 2500см3 заборонено</small>
                </div>
            </div>
        </div>

        <div class="col-lg-6">
            <div class="form-group">
                <multiselect
                    v-model="car.brand"
                    :options="carBrands"
                    :class="['form-control', {invalid: $v.car.brand.$error}]"
                    :taggable="true"
                    @input="/*getClientDataForUpdating()*/ car.model=null"
                    placeholder="Наприклад, Honda "
                    :tag-placeholder="'Натисніть Enter, щоб додати'"
                    :select-label="'Натисніть Enter, щоб вибрати'"
                    :remove-label="'Натисніть Enter, щоб очистити'"
                    :no-result="'Список пуст'"
                    @tag="addCar($event, 'brand')"
                ></multiselect>
<!--                label="name"-->
<!--                track-by="id"-->
<!--                @tag="addNewCar($event, 'brand')"-->

                <div v-if="$v.car.brand.$error">
                    <small class="error"
                           v-if="!$v.car.brand.required">Введіть марку авто</small>
                    <small class="error"
                           v-else-if="!$v.car.brand.minLength">Введіть
                        коректно марку авто</small>
                </div>
            </div>
        </div>
        <div class="col-lg-6">
            <div class="form-group">
                <multiselect
                    v-model="car.model"
                    :options="carModels"
                    :class="['form-control', {invalid: $v.car.model.$error}]"
                    :taggable="true"
                    placeholder="Наприклад, Civic"
                    :tag-placeholder="'Натисніть Enter, щоб додати'"
                    :select-label="'Натисніть Enter, щоб вибрати'"
                    :remove-label="'Натисніть Enter, щоб очистити'"
                    :no-result="'Список пуст'"
                    @tag="addCar($event, 'model')"
                ></multiselect>
<!--                label="name"-->
<!--                track-by="id"-->
<!--                @tag="addNewCar($event, 'model')"-->

                <div v-if="$v.car.model.$error">
                    <small class="error"
                           v-if="!$v.car.model.required">Введіть модель авто</small>
                    <small class="error"
                           v-else-if="!$v.car.model.minLength">Введіть
                        коректно модель авто</small>
                </div>
            </div>
        </div>
        <div class="col-lg-6">
            <div class="form-group">
                <input v-mask="'####'" type="text" v-model="car.year"
                       placeholder="Рік випуску"
                       :class="['form-control',{invalid: $v.car.year.$error}]">
                <div v-if="$v.car.year.$error">
                    <small class="error"
                           v-if="!$v.car.year.customMinLength">Введіть рік випуску авто</small>
                    <small class="error"
                           v-else-if="((!$v.car.year.customMinLength) || (!$v.car.year.maxLength) || (!$v.car.year.numeric))">
                        Введіть коректно рік випуску авто</small>
                </div>
            </div>
        </div>

        <div class="col-lg-6">
            <div class="form-group">
                <input v-model="car.vin" type="text" maxlength="17"
                       v-mask="'MMMMMMMMMMMMMMMMM'"
                       :disabled="car.withoutVin === true"
                       placeholder="VIN номер"
                       :class="['form-control', 'uppercase',{invalid: $v.car.vin.$error}]">
                <small>
                    <div class="form-check">
                        <input v-model="car.withoutVin" type="checkbox"  @click="disableVin()"  class="form-check-input" id="withoutCarVin">
                        <label class="form-check-label" for="withoutCarVin">Невідомий VIN</label>
                    </div>
                </small>
                <div v-if="$v.car.vin.$error">
                    <small class="error"
                           v-if="!$v.car.vin.required">Введіть VIN номер авто</small>
                    <small class="error"
                           v-else-if="! $v.car.vin.maxLength || ! $v.car.vin.minLength || checkVin()">Введіть
                        коректно VIN номер авто</small>
                </div>
            </div>
        </div>
        <div class="col-lg-6">
            <div class="form-group">
                <input v-mask="'#####'" type="text" v-model="car.ownWeight"
                       placeholder="Маса без навантаження, кг"
                       :class="['form-control',{invalid: $v.car.ownWeight.$error}]">
                <div v-if="$v.car.ownWeight.$error">
                    <small class="error"
                           v-if="!$v.car.ownWeight.required">Введіть масу без навантаження</small>
                    <small class="error"
                           v-else-if="(!$v.car.ownWeight.numeric)">
                        Введіть коректно масу без навантаження</small>
                </div>
            </div>
        </div>

        <div class="col-lg-6">
            <div class="form-group">
                <input v-mask="'#####'" type="text" v-model="car.totalWeight"
                       placeholder="Повна маса, кг"
                       :class="['form-control',{invalid: $v.car.totalWeight.$error}]">
                <div v-if="$v.car.totalWeight.$error">
                    <small class="error"
                           v-if="!$v.car.totalWeight.customMinLength">Введіть масу без навантаження</small>
                    <small class="error"
                           v-else-if="((!$v.car.totalWeight.customMinLength) || (!$v.car.totalWeight.maxLength) || (!$v.car.totalWeight.numeric))">
                        Введіть коректно масу без навантаження</small>
                </div>
            </div>
        </div>
        <div v-if="car.autoCategory !== 'E' && car.autoCategory !== 'F'" class="col-lg-12">
            <div class="row">
                <div class="col-lg-6">
                    <div class="form-group">
                        <input v-mask="'####'" type="text" v-model="car.nSeating"
                               placeholder="Кількість місць для сидіння, включно з місцем водія"
                               :class="['form-control',{invalid: $v.car.nSeating.$error}]">
                        <div v-if="$v.car.nSeating.$error">
                            <small class="error"
                                   v-if="!$v.car.nSeating.customMinLength">Введіть кількість місць</small>
                            <small class="error"
                                   v-else-if="((!$v.car.nSeating.customMinLength) || (!$v.car.nSeating.maxLength) || (!$v.car.nSeating.numeric))">
                                Введіть коректно кількість місць</small>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6" v-if="car.autoCategory === 'B5'">
                    <div class="form-group">
                        <input v-mask="'####'" type="text" v-model="car.kvt"
                               placeholder="Потужність (для електромобілів), кВт"
                               :class="['form-control',{invalid: $v.car.kvt.$error}]">
                        <div v-if="$v.car.kvt.$error">
                            <small class="error"
                                   v-if="!$v.car.kvt.customMinLength">Введіть потужність (для електромобілів), кВт</small>
                            <small class="error"
                                   v-else-if="((!$v.car.kvt.customMinLength) || (!$v.car.kvt.maxLength) || (!$v.car.kvt.numeric))">
                                Введіть потужність (для електромобілів), кВт</small>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6" v-else>
                    <div class="form-group">
                        <input v-mask="'#####'" type="text" v-model="car.engineSize"
                               placeholder="Об’єм двигуна"
                               :class="['form-control',{invalid: $v.car.engineSize.$error}]">
                        <div v-if="$v.car.engineSize.$error">
                            <small class="error"
                                   v-if="!$v.car.engineSize.customMinLength">Введіть об’єм двигуна</small>
                            <small class="error"
                                   v-else-if="((!$v.car.engineSize.customMinLength) || (!$v.car.engineSize.maxLength) || (!$v.car.engineSize.numeric))">
                                Введіть коректно об’єм двигуна</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
